<template>
  <v-container fluid>
    <v-card color="rgb(0 0 0 / 34%)" :outlined="false" value="true" z-index="10" v-if="data.name">
      <typical
        class="d-flex justify-center align-center"
        style="color:rgb(204 255 7)"
        :steps="['Hallo ' + data.name + '!', 1000]"
        :wrapper="'h1'"
      ></typical>
      <typical
        style="color:rgb(204 255 7)"
        class="d-flex justify-center align-center typicalWrapper"
        :steps="data.text != '' ? ['', 2000, data.text + ' ' + standard_text, 5000] : ['', 3000, standard_text, 5000]"
        :wrapper="'h3'"
      ></typical>
    </v-card>
    <v-card color="rgb(0 0 0 / 34%)" :outlined="false" value="true" z-index="10" v-else>

    </v-card>
  </v-container>
</template>

<script>
import crc32 from 'crc/crc32';
import typical from 'vue-typical'
export default {
  components: {
    typical
  },
  data () {
    return {
      data: null,
      standard_text: "Ich wünsche dir alles Gute auf deinem Weg. Wo immer du auch landest, so weit weg kanns nicht sein. Komm uns oft besuchen!",
      crc32: null,
      location: null,
      gettingLocation:false,
      base64: null
    }
  },
  mounted () {

    
  },
  created() {
    this.base64 = atob(this.$route.params.base64)
    this.crc32 = crc32(this.$route.params.base64).toString(16);
    this.data=JSON.parse(this.base64)
    //this.gettingLocation = true;
    //navigator.geolocation.getCurrentPosition(pos => {
    //  this.gettingLocation = false;
    //  this.location = pos.coords;
    //}, err => {
    //  this.gettingLocation = false;
    //  console.log(err)
    //})    
  }
}
</script>

<style scoped>
  #h1 {
    color:aqua
  }
</style>