<template>
  <v-container>
    <v-text-field style="background-color:white" :v-bind="text" @change="encode"></v-text-field>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      base32: null
    }
  },
  methods: {
    encode () {
      this.base32 = ""
    }
  }
}
</script>